import { Fragment, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";

const AssignPermissions = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [get_role_id, set_role_id] = useState();

    useEffect(() => {
        getRoles();
    }, [])
    const openAddModal = () => {
        setIsAddModalOpen(true)
    }


    async function getRoles() {
        const apiRes = await Api?.getActiveRoles();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setRoles(apiRes?.body);
            let data = apiRes?.body
            if (data.length) {
                handleChange(data[0].id)
            }
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    }

    async function handleChange(id) {
        set_role_id(id)
        let apiRes = await Api?.getRoleAndPermissionById(id);

        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setPermissions(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    }

    const add_Role_Permission = async (permission_id, e) => {
        let apiRes=await Api?.assignPermissionToRole(get_role_id,permission_id);
        handleChange(get_role_id)
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            // setPermissions(apiRes?.body);
            toast.success(apiRes?.message)
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    }
    return (

        <div className="right-nav-section admin-roles-permi" id="admin-roles-permi">
            <div className="dual-sec">
                <div className="page-heading">
                    <h3>Assign Permissions</h3>
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div id="roles" className="admin-roles">
                            <label>Select Role</label>
                            <select className="form-control" name="role" onChange={(e) => handleChange(e.target.value)} >
                                {roles.map((role) => (
                                    <option value={role?.id}>{role?.role_name}</option>
                                ))}
                            </select>
                        </div>

                        <div id="roles-and-permissions" className="admin-roles-permissions">
                            <div className="row">
                                {permissions.map((permission) => (
                                    <div className="col-md-3">
                                        <div className="permissions-check-p-head">
                                            <div className="permissions-check-p-inner">
                                                <input type="checkbox" name="vehicle1" checked={permission.status == 1} onChange={(e) => add_Role_Permission(permission?.tt_permission_id, e)} />{permission?.label}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignPermissions;
