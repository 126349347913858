import { Fragment } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import PublicRoute from "../publicprivateroute/PublicRoute";
import PrivateRoute from "../publicprivateroute/PrivateRoute";
import Layout from "../Layout";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import ProjectReportList from "../pages/ProjectReportList";
import EmployeesList from "../pages/EmployeesList";
import DepartmentsList from "../pages/DepartmentsList";
import DesignationsList from "../pages/DesignationsList";
import UpworkIdList from "../pages/Upwork";
import MyProfile from "../pages/MyProfile";
import EditProfile from "../pages/EditProfile";
import ChangePassword from "../pages/ChangePassword";
import AddNewEmployee from "../pages/AddNewEmployee";
import EditEmployee from "../pages/EditEmployee";
import AddNewProject from "../pages/AddNewProject";
import EditProject from "../pages/EditProject";
import EmployeeDetails from "../pages/EmployeeDetails";
import Roles from "../pages/Roles&Permissions/Roles";
import Permissions from "../pages/Roles&Permissions/Permissions";
import AssignPermissions from "../pages/Roles&Permissions/AssignPermissions";


const Routers = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route element={<Layout />}>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/project-report-list"
              element={
                <PrivateRoute>
                  <ProjectReportList />
                </PrivateRoute>
              }
            />
            <Route
              path="/employees-list"
              element={
                <PrivateRoute>
                  <EmployeesList />
                </PrivateRoute>
              }
            />
            <Route
              path="/departments-list"
              element={
                <PrivateRoute>
                  <DepartmentsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/designations-list"
              element={
                <PrivateRoute>
                  <DesignationsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/upwork-ids"
              element={
                <PrivateRoute>
                  <UpworkIdList />
                </PrivateRoute>
              }
            />
            <Route
              path="/my-profile"
              element={
                <PrivateRoute>
                  <MyProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/employees-list/add-new-employee"
              element={
                <PrivateRoute>
                  <AddNewEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/employees-list/edit-employee/:id"
              element={
                <PrivateRoute>
                  <EditEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/employees-list/view-employee/:id"
              element={
                <PrivateRoute>
                  <EmployeeDetails />
                </PrivateRoute>
              }
            />
             <Route
              path="/project-report-list/add-project"
              element={
                <PrivateRoute>
                  <AddNewProject />
                </PrivateRoute>
              }
            />
            <Route
              path="/project-report-list/edit-project/:id"
              element={
                <PrivateRoute>
                  <EditProject />
                </PrivateRoute>
              }
            />

            <Route
              path="/roles-permissions/role-list"
              element={
                <PrivateRoute>
                  <Roles />
                </PrivateRoute>
              }
            />
            <Route
              path="/roles-permissions/permission-list"
              element={
                <PrivateRoute>
                  <Permissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/roles-permissions/assign-permissions"
              element={
                <PrivateRoute>
                  <AssignPermissions />
                </PrivateRoute>
              }
            />

          </Route>
          <Route path="*" element={<h2>404</h2>} />
          
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routers;
