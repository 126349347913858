import { Navigate } from "react-router-dom";
import Auth from "../auth/Auth";


const PrivateRoute = ({ children }) => {
    const token = Auth.token();

    return (
        token ? children : <Navigate to={"/"} />
    );
};

export default PrivateRoute;