import { Fragment, useState, useEffect,useRef } from "react";

import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

const DesignationsList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: 'project-report.pdf' });

  const [designationList, setDesignationsList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addDestModal, setAddDestModal] = useState(false);
  const [error, setError] = useState(false);
  const [destName, setDestName] = useState("");
  const [updateDestModal, setUpdateDestModal] = useState(false);
  const [updateDestName, setUpdateDestName] = useState("");
  const [updateDestId, setUpdateDestId] = useState("");
  const [desId, setDesId] = useState("");

  const allDesignationsList = async () => {
    const apiRes = await Api?.getAllDesignationsList();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDesignationsList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleActiveToggle = async (id) => {
    let payload = {
      id,
    };
    const apiRes = await Api?.updateDstStatus(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      allDesignationsList();
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };
  const openDltModal = (id) => {
    setDesId(id);
    setDeleteModal(true);
  };

  const handleAddDest = async () => {
    setAddDestModal(true);
    if (!destName) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        destName,
      };
      let apiRes = await Api?.addDest(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setAddDestModal(false);
        setDestName("");
        allDesignationsList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const handleUpdateDest = async () => {
    setUpdateDestModal(true);
    if (!updateDestName) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        updateDestId,
        updateDestName,
      };
      let apiRes = await Api?.updateDest(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setUpdateDestModal(false);
        setUpdateDestName("");
        allDesignationsList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };


   /*********Delete Designation*******/
   const dltDesination = async () => {
    console.log("test")

    if (desId) {

      console.log(desId)
      let payload = {
        id: desId,
      };
      const apiRes = await Api?.deleteDesignation(payload);

      console.log(" -====",apiRes)

      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setDeleteModal(false);
        setDesId(null);
        toast.success(apiRes?.message);
        allDesignationsList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };



  useEffect(() => {
    allDesignationsList();
  }, []);

  return (
    <Fragment>
      <div className="designation_list_head right-nav-section">
        {/* <div>
          <h3>Designations List</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setAddDestModal(true)}
          >
            Add Designation
          </button>
        </div> */}

        <div className="dual-sec">
            <div className="page-heading">
            <h3>Designations List</h3>
            </div>
            <div className="add-btn">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setAddDestModal(true)}
              >
                Add Designation
              </button>
            </div>
        </div>
        <div className="dual-exports">
          <div className="exprt-excel clickable">
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <i class="fa-solid fa-file-excel 2px"></i>
            </DownloadTableExcel>
          </div>
          <div className="exprt-pdf clickable">
            <span onClick={() => toPDF()}>
              <i class="fa-solid fa-file-pdf"></i>
            </span>
          </div>
        </div>
        <Table striped bordered hover size="sm" ref={targetRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Designation Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {designationList &&
              designationList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.name}</td>
                    <td>
                      <ToggleButton
                        value={ele?.status === 1 ? true : false}
                        onToggle={() => handleActiveToggle(ele?.id)}
                        activeLabel=""
                        inactiveLabel=""
                      />
                    </td>
                    <td>
                        <div className="inner-actions">
                            <div className="edit-action" 
                                 onClick={() => {
                                  setUpdateDestModal(true);
                                  setUpdateDestName(ele?.name);
                                  setUpdateDestId(ele?.id);
                                }}
                            >
                                <i className="fas fa-edit"></i>
                            </div>

                            <div className="trash-action"
                                //onClick={() => setDeleteModal(true)}

                               onClick={()=>openDltModal(ele?.id)} 
                            >
                                <i className="fas fa-trash"></i>
                            </div>
                      </div>
                      {/* <button type="button" className="btn btn-warning" onClick={() => {
                          setUpdateDestModal(true);
                          setUpdateDestName(ele?.name);
                          setUpdateDestId(ele?.id);
                        }}>
                        Update
                      </button>
                      <br />
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Table striped bordered hover size="sm" ref={tableRef} style={{display:"none"}}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Designation Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {designationList &&
              designationList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.name}</td>
                    <td>
                    {ele?.status === 1 ? "Active" : "Inactive"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      {/* Designation Delete Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Designation Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Designation!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
          {/* <Button variant="primary" onClick={() => setDeleteModal(false)}>
            Yes
          </Button> */}
          <Button variant="primary" onClick={()=>dltDesination()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Designation Modal */}
      <Modal
        show={addDestModal}
        onHide={() => {
          setAddDestModal(false);
          setError(false);
          setDestName("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Designation Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the designation name"
                value={destName}
                onChange={(e) => setDestName(e.target.value)}
              />
              {error && !destName && (
                <span className="text-danger">
                  Please enter the designation name
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAddDestModal(false);
              setError(false);
              setDestName("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleAddDest()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Designation Modal */}
      <Modal
        show={updateDestModal}
        onHide={() => {
          setUpdateDestModal(false);
          setError(false);
          setUpdateDestName("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Designation Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the designation name"
                value={updateDestName}
                onChange={(e) => setUpdateDestName(e.target.value)}
              />
              {error && !updateDestName && (
                <span className="text-danger">
                  Please enter the designation name
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpdateDestModal(false);
              setError(false);
              setUpdateDestName("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdateDest()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      
    </Fragment>
  );
};

export default DesignationsList;
