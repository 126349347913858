import { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer className="py-2 footer-bg">
        <div className="container-fluid px-md-4">
          <div className="d-flex align-items-center justify-content-between small flex-column flex-md-row">
            <div className="text-muted">Copyright &copy; Your Website 2023</div>
            <div className="policy">
              <a href="#">Privacy Policy</a>
              &middot;
              <a href="#">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
