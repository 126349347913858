import { Fragment, useEffect } from "react";

import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  useEffect(() => {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");
    const handleSidebarToggle = (event) => {
      event.preventDefault();
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    };

    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarToggle);
    }
    return () => {
      if (sidebarToggle) {
        sidebarToggle.removeEventListener("click", handleSidebarToggle);
      }
    };
  }, []);

  return (
    <Fragment>
      <div className="sb-nav-fixed">
        <Header />
        <div id="layoutSidenav">
          <Sidebar />
          <div id="layoutSidenav_content">
            <main>
              <div className="container-fluid px-md-4">
                <div className="main_content">
                <Outlet />
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
