import { Fragment } from "react";

import { Form } from "react-bootstrap";

const CustomInput = (props) => {
  return (
    <Fragment>
      <Form.Control {...props} />
    </Fragment>
  );
};

export default CustomInput;
