import { Fragment, useState, useEffect,useRef } from "react";

import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

const UpworkIdList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: 'project-report.pdf' });

  const [upworkIdList, setUpworkIdList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addUpworkModal, setAddUpworkModal] = useState(false);
  const [error, setError] = useState(false);
  const [upworkId, setUpworkId] = useState("");
  const [updateUpworkModal, setUpdateUpworkModal] = useState(false);
  const [updateUpworkId, setUpdateUpworkId] = useState("");
//   const [updateDestId, setUpdateDestId] = useState("");
const [updateUpwork_id, setUpdateUpwork_id] = useState(""); //Row id

  const allUpworkIdList = async () => {
    const apiRes = await Api?.getAllUpworkIds();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setUpworkIdList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleActiveToggle = async (id) => {
    let payload = {
      id,
    };
    const apiRes = await Api?.updateUpworkIdStatus(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      allUpworkIdList();
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleAddUpworkId = async () => {
    setAddUpworkModal(true);
    if (!upworkId) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        upworkId,
      };
      let apiRes = await Api?.addUpworkId(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setAddUpworkModal(false);
        setUpworkId("");
        allUpworkIdList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const handleUpdateUpworkId = async () => {
    setUpdateUpworkModal(true);
    if (!updateUpworkId) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        updateUpwork_id,
        updateUpworkId,
      };
      let apiRes = await Api?.updateUpworkId(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setUpdateUpworkModal(false);
        setUpdateUpworkId("");
        allUpworkIdList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const openDltModal = (id) => {
    setUpworkId(id);
    setDeleteModal(true);
  };


  const dltUpwork = async () => {
    console.log("test")
  
    if (upworkId) {
  
      console.log(upworkId)
      let payload = {
        id: upworkId,
      };

      let apiRes = await Api?.deleteUpwork(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setDeleteModal(false);
        setUpworkId(null);
        toast.success(apiRes?.message);
        allUpworkIdList();
      }
      
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };




  useEffect(() => {
    allUpworkIdList();
  }, []);

  return (
    <Fragment>
      <div className="upwork_id_list_head right-nav-section">
        <div className="dual-sec">
            <div className="page-heading">
            <h3>Upwork Id List</h3>
            </div>
            <div className="add-btn">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setAddUpworkModal(true)}
                >
                Add Upwork Id
            </button>
            </div>
        </div>
        <div className="dual-exports">
          <div className="exprt-excel clickable">
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <i class="fa-solid fa-file-excel 2px"></i>
            </DownloadTableExcel>
          </div>
          <div className="exprt-pdf clickable">
            <span onClick={() => toPDF()}>
              <i class="fa-solid fa-file-pdf"></i>
            </span>
          </div>
        </div>
        <Table striped bordered hover size="sm" ref={targetRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Upwork Id</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {upworkIdList &&
              upworkIdList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.upwork_id}</td>
                    <td>
                      <ToggleButton
                        value={ele?.status === 1 ? true : false}
                        onToggle={() => handleActiveToggle(ele?.id)}
                        activeLabel=""
                        inactiveLabel=""
                      />
                    </td>
                    <td>
                        <div className="inner-actions">
                            <div className="edit-action" 
                                 onClick={() => {
                                    setUpdateUpworkModal(true);
                                    setUpdateUpworkId(ele?.upwork_id);
                                    setUpdateUpwork_id(ele?.id);
                                 }}
                            >
                                <i className="fas fa-edit"></i>
                            </div>

                            <div className="trash-action"
                               onClick={() => openDltModal(ele?.id)}
                            >
                                <i className="fas fa-trash"></i>
                            </div>
                            {/* <button type="button" className="btn btn-warning" onClick={() => {
                            setUpdateUpworkModal(true);
                            setUpdateUpworkId(ele?.upwork_id);
                            setUpdateUpwork_id(ele?.id);
                            }}>
                            Update
                            </button>
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => setDeleteModal(true)}
                            >
                                Delete
                            </button> */}
                        </div>

                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Table striped bordered hover size="sm" ref={tableRef} style={{display:"none"}}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Upwork Id</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {upworkIdList &&
              upworkIdList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.upwork_id}</td>
                    <td>
                    {ele?.status === 1 ? "Active" : "Inactive"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      {/* Designation Delete Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upwork Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Upwork id!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => dltUpwork()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Designation Modal */}
      <Modal
        show={addUpworkModal}
        onHide={() => {
            setAddUpworkModal(false);
          setError(false);
          setUpworkId("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Upwork Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Upwork Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the Upwork id"
                value={upworkId}
                onChange={(e) => setUpworkId(e.target.value)}
              />
              {error && !upworkId && (
                <span className="text-danger">
                  Please enter the Upwork id
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
                setAddUpworkModal(false);
              setError(false);
              setUpworkId("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleAddUpworkId()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Designation Modal */}
      <Modal
        show={updateUpworkModal}
        onHide={() => {
          setUpdateUpworkModal(false);
          setError(false);
          setUpdateUpworkId("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Upwork Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Upwork Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the Upwork id"
                value={updateUpworkId}
                onChange={(e) => setUpdateUpworkId(e.target.value)}
              />
              {error && !updateUpworkId && (
                <span className="text-danger">
                  Please enter the  Upwork id
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpdateUpworkModal(false);
              setError(false);
              setUpdateUpworkId("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdateUpworkId()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      
    </Fragment>
  );
};

export default UpworkIdList;
