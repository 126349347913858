import { Fragment, useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import { AppContext } from "../../context/AppContext";
import ProfileMenImg from "../../assets/images/profilemen.png";

const AddNewEmployee = () => {
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const [designationsList, setDesignationsList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [teamLeadsList, setTeamLeadsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [inputData, setInputData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    dpt_id: "",
    dst_id: "",
    skype_id: "",
    team_lead_id: "",
    role_id: "",
    password: "",
    conf_password: "",
    profile_pic: ""
  });
  const [image, setImage] = useState(null);
  const [passEye, setPassEye] = useState({
    pass: false,
    confirm: false
  });
  const [loader, setLoader] = useState(false)
  const [showingImg, setShowingImg] = useState(null);
  const [error, setError] = useState(false);

  const getDesignationsData = async () => {
    const apiRes = await Api?.getActiveDesignations();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDesignationsList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error("Something went wrong");
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const getDepartmentsData = async () => {
    const apiRes = await Api?.getActiveDepartments();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDepartmentsList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error("Something went wrong");
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const getTeamLeadsData = async () => {
    const apiRes = await Api?.getActiveTeamLeads();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setTeamLeadsList(apiRes?.body);
      console.log("apiRes?.body", apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error("Something went wrong");
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const getRolesData = async () => {
    const apiRes = await Api?.getActiveRoles();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setRolesList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error("Something went wrong");
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getDesignationsData();
    getDepartmentsData();
    getTeamLeadsData();
    getRolesData();
  }, []);

  const handleProfileFile = (e) => {
    const file = e.target.files[0];
    setInputData({ ...inputData, profile_pic: file });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setError(true)
    if (!inputData.f_name || !inputData.email || !inputData.dpt_id || !inputData.dst_id || !inputData.skype_id || !inputData.role_id || !inputData.password || !inputData?.conf_password || !emailRegex.test(inputData?.email)||inputData.password !== inputData?.conf_password) {
      return;

    } else {
      setError(false)
      setLoader(true);
      let apiRes = await Api?.registerEmployee(inputData);
      setLoader(false);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        navigate("/employees-list")
        toast.success(apiRes?.message)
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const changeDept = (e) => {
    setInputData({ ...inputData, dpt_id: e.target.value })
  }

  const changeDest = (e) => {
    setInputData({ ...inputData, dst_id: e.target.value })
  }

  const changeTeamLead = (e) => {
    setInputData({ ...inputData, team_lead_id: e.target.value })
  }

  const changeRole = (e) => {
    setInputData({ ...inputData, role_id: e.target.value })
  }

  const handleKeyPress = (event) => {
    // Allow only alphabets and certain special characters
    const regex = /^[A-Za-z.,'"\s]+$/;
    const isValidInput = regex.test(event.key);

    if (!isValidInput) {
      event.preventDefault();
    }
  };
  return (
    <Fragment>
      <div id="add-emp-details" className="right-nav-section">
        <div className="row">
          <div className="col-12">
            <div className="profile_shade">
              <div className="grade_content profile_title">
                <h2>Add New Employee</h2>
                <Link className="back-to" to="/employees-list">Back</Link>
              </div>
              <hr className="m-0" />
              <div className="row">
                <div className="col-12">
                  <div className="update_feild">
                    <div className="row align-items-center">
                      <div className="col-lg-2">
                        <div className="profile_menimg position-relative">
                          <img
                            className="w-100"
                            src={image ? image : ProfileMenImg}
                            alt="profile"
                          />
                          <div className="edit_icon">
                            <input
                              name="files[]"
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleProfileFile(e)}
                            // onClick={(e) => {
                            //   e.target.value = null;
                            // }}
                            />
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.75 13.5H6.93C7.0287 13.5006 7.12655 13.4817 7.21793 13.4443C7.30931 13.407 7.39242 13.352 7.4625 13.2825L12.6525 8.08502L14.7825 6.00002C14.8528 5.93029 14.9086 5.84734 14.9467 5.75595C14.9847 5.66455 15.0043 5.56652 15.0043 5.46752C15.0043 5.36851 14.9847 5.27048 14.9467 5.17908C14.9086 5.08769 14.8528 5.00474 14.7825 4.93502L11.6025 1.71752C11.5328 1.64722 11.4498 1.59142 11.3584 1.55335C11.267 1.51527 11.169 1.49567 11.07 1.49567C10.971 1.49567 10.873 1.51527 10.7816 1.55335C10.6902 1.59142 10.6072 1.64722 10.5375 1.71752L8.4225 3.84002L3.2175 9.03752C3.14799 9.1076 3.093 9.19071 3.05567 9.28209C3.01835 9.37346 2.99943 9.47131 3 9.57001V12.75C3 12.9489 3.07902 13.1397 3.21967 13.2803C3.36032 13.421 3.55109 13.5 3.75 13.5ZM11.07 3.30752L13.1925 5.43002L12.1275 6.49502L10.005 4.37252L11.07 3.30752ZM4.5 9.87752L8.9475 5.43002L11.07 7.55252L6.6225 12H4.5V9.87752ZM15.75 15H2.25C2.05109 15 1.86032 15.079 1.71967 15.2197C1.57902 15.3603 1.5 15.5511 1.5 15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15Z"
                                fill="black"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div className="checkout_field edit_field">
                          <div className="row">
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  First Name <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="First Name"
                                  value={inputData?.f_name}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      f_name: e.target.value,
                                    })
                                  }
                                  onKeyPress={handleKeyPress}
                                />
                                {error && !inputData?.f_name && (
                                  <span className="text-danger">
                                    Please enter first name
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Last Name (Optional)
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Last Name"
                                  value={inputData?.l_name}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      l_name: e.target.value,
                                    })
                                  }
                                  onKeyPress={handleKeyPress}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Email Address <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Email Address"
                                  value={inputData?.email}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      email: e.target.value,
                                    })
                                  }
                                />
                                {error && (!inputData?.email ?
                                  <span className="text-danger">
                                    Please enter email address
                                  </span>
                                :!emailRegex.test(inputData?.email) && <span className="text-danger">
                                Please enter a valid email address.
                              </span>)}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Department <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => changeDept(e)}
                                  defaultValue="Select department"
                                >
                                  <option disabled hidden>Select department</option>
                                  {departmentsList &&
                                    departmentsList.map((ele) => (
                                      <option key={ele?.id} value={ele?.id}>
                                        {ele?.name}
                                      </option>
                                    ))}
                                </select>
                                {error && !inputData?.dpt_id && (
                                  <span className="text-danger">
                                    Please select department
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Designation <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => changeDest(e)}
                                  defaultValue="Select designation"
                                >
                                  <option disabled hidden>Select designation</option>
                                  {designationsList &&
                                    designationsList.map((ele) => (
                                      <option key={ele?.id} value={ele?.id}>
                                        {ele?.name}
                                      </option>
                                    ))}
                                </select>
                                {error && !inputData?.dst_id && (
                                  <span className="text-danger">
                                    Please select designation
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Skype Id <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Skype Id"
                                  value={inputData?.skype_id}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      skype_id: e.target.value,
                                    })
                                  }
                                />
                                {error && !inputData?.skype_id && (
                                  <span className="text-danger">
                                    Please enter skype id
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Team Lead (Optional)
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => changeTeamLead(e)}
                                  defaultValue="Select team lead"
                                >
                                  <option disabled hidden>Select team lead</option>
                                  {teamLeadsList &&
                                    teamLeadsList.map((ele) => (
                                      <option key={ele?.id} value={ele?.id}>
                                        {ele?.first_name + "" + ele?.last_name}
                                      </option>
                                    ))}
                                </select>
                                {/* {error && !inputData?.f_name && (
                                  <span className="text-danger">
                                    Please select team lead
                                  </span>
                                )} */}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Role <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                      stroke="#2D2926"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                      stroke="#2D2926"
                                      stroke-width="1.42857"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => changeRole(e)}
                                  defaultValue="Select role"
                                >
                                  <option disabled hidden>Select role</option>
                                  {rolesList &&
                                    rolesList.map((ele) => (
                                      <option key={ele?.id} value={ele?.id}>
                                        {ele?.role_name}
                                      </option>
                                    ))}
                                </select>
                                {error && !inputData?.role_id && (
                                  <span className="text-danger">
                                    Please select role
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Password <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <i
                                    className={passEye.password ? "far fa-eye" : "far fa-eye-slash"}
                                    onClick={() => setPassEye({ ...passEye, password: !passEye.password })}
                                  ></i>
                                </div>
                                <input
                                  type={`${passEye?.password ? 'text' : 'password'}`}
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="*****************"
                                  value={inputData?.password}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      password: e.target.value,
                                    })
                                  }
                                />
                                {error && !inputData?.password && (
                                  <span className="text-danger">
                                    Please enter password.
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Confirm Password <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                </label>
                                <div className="icon">
                                  <i
                                    className={passEye.confirm ? "far fa-eye" : "far fa-eye-slash"}
                                    onClick={() => setPassEye({ ...passEye, confirm: !passEye.confirm })}
                                  ></i>
                                </div>
                                <input
                                  type={`${passEye?.confirm ? 'text' : 'password'}`}
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="*****************"
                                  value={inputData?.conf_password}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      conf_password: e.target.value,
                                    })
                                  }
                                />
                                {error && (!inputData?.conf_password ?
                                  <span className="text-danger">Please enter confirm password.</span>
                                  : inputData?.password !== inputData?.conf_password && <span className="text-danger">Confirm password is not matched.</span>)
                                }
                              </div>
                            </div>
                          </div>
                          <Button
                            variant="success"
                            onClick={() => handleSubmit()}
                            disabled={loader}
                          >
                            {loader && <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> }
                             {" "}Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddNewEmployee;
