import { Fragment, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";

const Permissions = () => {
  const navigate = useNavigate();
  const [departmentsList, setDepartmentsList] = useState([]);
  const allDepartmentsList = async () => {
    const apiRes = await Api?.getPermissions();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDepartmentsList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    allDepartmentsList();
  }, []);

  return (
    <Fragment>
      <div className="admin-permissions right-nav-section">
        <div className="dual-sec">
            <div className="page-heading">
            <h3>Permission List</h3>
            </div>
        </div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Permission</th>
              {/* <th>Status</th> */}
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {departmentsList &&
              departmentsList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.label}</td>
                    {/* <td>
                      <ToggleButton
                        value={ele?.status === 1 ? true : false}
                        onToggle={() => handleActiveToggle(ele?.id)}
                        activeLabel=""
                        inactiveLabel=""
                      />
                    </td>
                    <td>
                        <div className="inner-actions">
                            <div className="edit-action" 
                                  onClick={() => {
                                    setUpdateDptModal(true);
                                    setUpdateDptName(ele?.name);
                                    setUpdateDptId(ele?.id);
                                  }}
                            >
                                <i className="fas fa-edit"></i>
                            </div>

                            <div className="trash-action"
                              onClick={() => setDeleteModal(true)}
                            >
                                <i className="fas fa-trash"></i>
                            </div>
                        </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default Permissions;
